<template>
<div>
<div class="users" v-show="loggedin">
  <h2 style="margin-bottom:10px;color:white">User Details </h2>
  <v-btn class="ma-2" @click="dialog1 = true" style="color:#263AA2">Add</v-btn>
  <v-dialog v-model="dialog1" max-width="40%" :fullscreen="$vuetify.breakpoint.smAndDown">
    <form @submit.prevent="add" class="updateusers">
      <validation-provider
        v-slot="{ errors }"
        name="Username"
        rules="required|min:5"
      >
      <v-text-field
        v-model="username"
        :error-messages="errors"
        label="Username"
      ></v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Password"
        rules="required|min:8"
      >
      <v-text-field
        v-model="password"
        :error-messages="errors"
        label="Password"
        required
      ></v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Active Status"
        rules="required"
      >
      <v-select
        v-model="active"
        :error-messages="errors"
        label="Active"
        :items="status"
        required
      ></v-select>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Realme 5 Pro"
        rules="required"
      >
      <v-select
        v-model="rmx1971"
        :error-messages="errors"
        label="Realme 5 Pro"
        :items="status"
        required
      ></v-select>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Realme 5 Series"
        rules="required"
      >
      <v-select
        v-model="r5x"
        :error-messages="errors"
        label="Realme 5 Series"
        :items="status"
        required
      ></v-select>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Actions on builds"
        rules="required"
      >
      <v-select
        v-model="builds"
        :error-messages="errors"
        label="Actions on builds"
        :items="status"
        required
      ></v-select>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Actions on blog"
         rules="required"
      >
      <v-select
        v-model="blog"
        :error-messages="errors"
        label="Actions on blog"
        :items="status"
        required
      ></v-select>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Actions on maintainers"
         rules="required"
      >
      <v-select
        v-model="maintainers"
        :error-messages="errors"
        label="Actions on maintainers"
        :items="status"
        required
      ></v-select>
      </validation-provider>
      <v-btn
        color="primary"
        class="mr-4"
        type="submit"
      >
      Submit
      </v-btn>
      <v-btn
        class="ma-2"
        @click="dialog1 = false"
      >
      Close
      </v-btn>
    </form>
  </v-dialog>
  <v-expansion-panels>
    <v-expansion-panel
      v-for="(item,i) in this.length"
      :key="i"
      style="background:#467cc1;color:white;"      
    >
      <v-expansion-panel-header>
        <div style="letter-spacing: 2px">Username : {{ userdata[i].user_name }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
          <p class="users1" >Active Status : {{ userdata[i].active }}</p>
          <p class="users1" >Admin Status : {{ userdata[i].admin }}</p>
          <p class="users1" >Realme 5 Pro : {{ userdata[i].rmx1971 }}</p>
          <p class="users1" >Realme 5 Series : {{ userdata[i].r5x }}</p>
          <p class="users1" >Action on builds : {{ userdata[i].actions_on_builds }}</p>
          <p class="users1" >Action on blogs : {{ userdata[i].actions_on_blogs }}</p>
          <p class="users1" >Action on maintainers : {{ userdata[i].actions_on_maintainers }}</p>
          <p class="users1" >Created By : {{ userdata[i].created_by }}</p>
          <p class="users1" >Updated By : {{ userdata[i].updated_by }}</p>
          <v-btn
            class="ma-2"
            style="color:#263AA2"
            @click="dialog = true"
          >
            Edit
          </v-btn>
            <v-dialog v-model="dialog" max-width="40%" :fullscreen="$vuetify.breakpoint.smAndDown">
              <form @submit.prevent="update" class="updateusers">
                <validation-provider
                  v-slot="{ errors }"
                  name="Username"
                >
                  <v-text-field
                    :value="userdata[i].user_name"
                    ref="username"
                    :error-messages="errors"
                    label="Username"
                    disabled
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  rules="required|min:8"
                >
                  <v-text-field
                    ref="password"
                    v-model="password"
                    :error-messages="errors"
                    label="Password"
                    required
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Active Status"
                  rules="required"
                >
                  <v-select
                    :value="userdata[i].active"
                    ref="active"
                    :error-messages="errors"
                    label="Active"
                    :items="status"
                    required
                  ></v-select>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Realme 5 Pro"
                  rules="required"
                >
                  <v-select
                    :value="userdata[i].rmx1971"
                    ref="rmx1971"
                    :error-messages="errors"
                    label="Realme 5 Pro"
                    :items="status"
                    required
                  ></v-select>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Realme 5 Series"
                  rules="required"
                >
                  <v-select
                    :value="userdata[i].r5x"
                    ref="r5x"
                    :error-messages="errors"
                    label="Realme 5 Series"
                    :items="status"
                    required
                  ></v-select>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Actions on builds"
                  rules="required"
                >
                  <v-select
                    :value="userdata[i].actions_on_builds"
                    ref="builds"
                    :error-messages="errors"
                    label="Actions on builds"
                    :items="status"
                    required
                  ></v-select>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Actions on blog"
                  rules="required"
                >
                  <v-select
                    :value="userdata[i].actions_on_blogs"
                    ref="blog"
                    :error-messages="errors"
                    label="Actions on blog"
                    :items="status"
                    required
                  ></v-select>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Actions on maintainers"
                  rules="required"
                >
                  <v-select
                  :value="userdata[i].actions_on_maintainers"
                    ref="maintainers"
                    :error-messages="errors"
                    label="Actions on maintainers"
                    :items="status"
                    required
                  ></v-select>
                </validation-provider>
                <v-btn
                color="primary"
                  class="mr-4"
                  type="submit"
                >
                  Submit
                </v-btn>
                <v-btn
                  class="ma-2"
                  @click="dialog = false"
                >
                  Close
                </v-btn>
              </form>
            </v-dialog>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-alert outlined transition="slide-y-transition" type="success" text :value="updateduserdata"> User Data Updated successfully. </v-alert>
  <v-alert outlined type="error" text :value="failedupdateuserdata"> Failed to Update User Data. </v-alert>
  <v-alert outlined transition="slide-y-transition" type="success" text :value="addeduserdata"> User Created successfully. </v-alert>
  <v-alert outlined type="error" text :value="failedadduserdata"> Failed to Create User. </v-alert>
</div>
  <div class="notfound" v-show="notloggedin">
    <img src="../assets/404.png" alt="404(Not Found)">
    <h1>404</h1>
    <p>The page you are looking for is not available</p>
  </div>
</div>
</template>
<script>
import { required, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import axios from 'axios';

  setInteractionMode('eager')

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('min', {
    ...min,
    message: '{_field_} must have atlest {length} characters',
  })

  export default {
    name: 'Users',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data: () => ({
      length: '',
      userdata: '',
      current_time: '',
      dialog: false,
      dialog1: false,
      status: ['Y', 'N'],
      updateduserdata: false,
      failedupdateuserdata: false,
      addeduserdata: false,
      failedadduserdata: false,
      loggedin: false,
      notloggedin: true,
    }),

    mounted () {
        this.checkadminstatus();
        this.getusers();
    },

    methods: {
        checkadminstatus() {
            this.current_time = Math.round(+new Date()/1000)
            let data = JSON.parse(sessionStorage.getItem("data"))
            if (data && data['admin']=='Y' && sessionStorage.getItem("expire_time") > this.current_time) {
              this.loggedin = true,
              this.notloggedin = false
            } else {
              sessionStorage.clear();
            }
        },
        getusers() {
            let config = {
                method : 'get',
                url : `https://kharame-devices.herokuapp.com/users`,
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };
            axios(config)
            .then((response) => {
                response.data.message
                this.length = response.data.message.length
                this.userdata = response.data.message
            })
        },
      update () {
        if (this.$refs.password[0].value.length > 7) {
          let data = {
            username : this.$refs.username[0].value,
            password : this.$refs.password[0].value,
            active : this.$refs.active[0].selectedItems[0],
            rmx1971: this.$refs.rmx1971[0].selectedItems[0],
            r5x: this.$refs.r5x[0].selectedItems[0],
            builds: this.$refs.builds[0].selectedItems[0],
            blog: this.$refs.blog[0].selectedItems[0],
            maintainers: this.$refs.maintainers[0].selectedItems[0],
          };
          let config = {
            method : 'post',
            url : `https://kharame-devices.herokuapp.com/updateuser`,
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },
            data: data
          };
          axios(config)
          .then((response) => {
            if (response.data && response.data.status === "S" && response.data.message === "S") {
              this.dialog = false;
              this.updateduserdata = true;
              this.getusers();
              setTimeout(() => this.updateduserdata = false, 5000);
            } else {
              this.dialog = false
              this.failedupdateuserdata = true;
              setTimeout(() => this.failedupdateuserdata = false, 5000);
            }
          })
        }
      },
      add () {
        if (this.password.length > 7) {
          let data = {
            username : this.username,
            password : this.password,
            active : this.active,
            rmx1971: this.rmx1971,
            r5x: this.r5x,
            builds: this.builds,
            blog: this.blog,
            maintainers: this.maintainers,
          };
          let config = {
            method : 'post',
            url : `https://kharame-devices.herokuapp.com/createuser`,
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },
            data: data
          };
          axios(config)
          .then((response) => {
            console.log(response.data)
            if (response.data && response.data.status === "S" && response.data.message === "S") {
              this.dialog1 = false;
              this.addeduserdata = true;
              this.getusers();
              setTimeout(() => this.addeduserdata = false, 5000);
            } else {
              this.dialog1 = false
              this.failedadduserdata = true;
              setTimeout(() => this.failedadduserdata = false, 5000);
            }
          })
        }
      },
    },
  }
</script>
<style scoped>
.users {
  width : 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
}
.users1 {
  margin: 2%;
  border-bottom: groove;
  border-width: 2px;
  letter-spacing: 2px;
}
.updateusers {
  background: white;
  padding: 10%;
}
.notfound {
	text-align: center;
	margin-top: 7%;
  color: white;
}
.dialog {
  max-width : 40%;
}
@media (max-width: 550px) {
  .dialog {
    width: 80%;
  }
}
</style>